.navbar-dark {
  .navbar-brand {
    font-family: "1898 Sans", sans-serif;
    font-weight: bold;
    color: #fff;
  }

  .navbar-nav .nav-link {
    color: #fff;
  }

  .navbar-toggler {
    color: #fff;
    border-color: theme-color("secondary");
    background-color: theme-color("secondary");
  }
}
