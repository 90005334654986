.hero {
  background-color: transparent;
  // height: 12rem;
  // max-height: 12rem;

  .hero-content {
    margin-top: 5rem;
    margin-bottom: 5rem;
    background-color: rgba(0, 0, 0, 0.76);
    padding: 3rem 5rem;
    h1 {
      color: #fff;
    }
    p {
      font-size: x-large;
      color: #fff;
    }
  }

  .image-background {
    background: url('./../img/intro/hero.jpg') bottom center;
    background-position: center;
    background-size: cover;
    filter: blur(1px);
    margin: -3px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -100;
  }
}

.site-footer {
  font-size: small;
  .list-group-item {
    border: none;
  }
}

.site-faq-list {
  .list-group-item.active {
    color: white;
  }

  .list-group-item-action {
    color: theme-color("secondary");
  }
}

.carouselCaptionBlackMask {
  background-color: rgba(0, 0, 0, 0.76);
}