$colors: (
  "wcm-red": #B31B1B,
  "wcm-dark-orange": #CF4520,
  "wcm-bright-orange": #E87722,
  "wcm-yellow": #FFC72C
);

$theme-colors: (
  "primary": #CF4520,
  "secondary": #B31B1B
);
