@font-face {
  font-family: '1898 Sans';
  font-weight: normal;
  font-style: normal;
  src: url('./../fonts/1898Sans/1898Sans-Regular.ttf') format('truetype');  
}

@font-face {
  font-family: '1898 Sans';
  font-weight: bold;
  font-style: normal;
  src: url('./../fonts/1898Sans/1898Sans-Bold.ttf') format('truetype');  
}

@font-face {
  font-family: '1898 Sans';
  font-weight: bold;
  font-style: italic;
  src: url('./../fonts/1898Sans/1898Sans-BoldItalic.ttf') format('truetype');  
}

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: normal;
  src: url('./../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');  
}

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: italic;
  src: url('./../fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');  
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-style: normal;
  src: url('./../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');  
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-style: italic;
  src: url('./../fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');  
}