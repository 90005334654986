.monthSelector {
  box-sizing: border-box;
  color: #444;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  line-height: 34px;
  width: 100%;
  display: flex;
}

.monthDisplay {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  flex-grow: 4;
  text-align: center;
  padding: 0 3px;
}

.clickable {
  font-family: serif; /* To prevent issues with the arrow icons */
  padding: 0 3px;
  align-self: center;
}

.clickable:hover {
  background: #ddd;
  cursor: pointer;
}